<div class="sidebar js-sidebar" [ngClass]="sidebarActive ? 'active':''">
    <div class="sidebar-layout row">
        <div class="col col-lg-3">
            <button class="sidebar-toggle btn btn-primary btn-circle" type="button" (click)="toggleSidebar()">
                <svg class="btn-icon" width="13" height="22" viewBox="0 0 13 22" fill="none" role="img"
                    aria-hidden="true" focusable="false">
                    <path d="M1.66666 19.6667L11 11L1.66666 2.33333" stroke="currentColor" stroke-width="2"
                        stroke-linecap="square"></path>
                </svg>
                <span class="sr-only">Expand menu</span>
            </button>
            <div class="sidebar-container" style="height: 272px;">
                <ul class="sidebar-list nav has-height" role="tablist" aria-orientation="vertical">
                    <li class="sidebar-item nav-item">
                        <a id="app-tab" class="sidebar-link nav-link" [ngClass]="{ 'active' : myApplications }"
                            routerLink="myApplications" data-toggle="tab" data-target="#app" role="tab"
                            aria-controls="app" aria-selected="true" title="My applications"
                            (click)="loadTitle('my-applications'); this.loadSVG('myApplications')">
                            <svg class="sidebar-link-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="12" cy="2" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="12" cy="22" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="2" cy="12" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="2" cy="2" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="2" cy="22" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="22" cy="12" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="22" cy="2" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                                <circle cx="22" cy="22" r="1.5" fill="#3F4D62" stroke="#3F4D62"></circle>
                            </svg>
                            <span class="sidebar-link-text text-truncate" translate>my-applications</span>
                        </a>
                    </li>
                    <li class="sidebar-item nav-item">
                        <a id="profile-tab" class="sidebar-link nav-link" [ngClass]="{ 'active' : manageProfile }"
                            routerLink="manageProfile" data-toggle="tab" data-target="#profile" role="tab"
                            aria-controls="profile" aria-selected="false" title="Profile"
                            (click)="loadTitle('my-profile') ; this.loadSVG('manageProfile')">
                            <svg class="sidebar-link-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M5.59999 20.8L5.59999 19.9998C5.59999 17.3488 7.74902 15.2 10.4 15.2H13.6C16.251 15.2 18.4 17.3488 18.4 19.9998V20.8M12 5.6C10.2327 5.6 8.79999 7.03269 8.79999 8.8C8.79999 10.5673 10.2327 12 12 12C13.7673 12 15.2 10.5673 15.2 8.8C15.2 7.03269 13.7673 5.6 12 5.6ZM12 23.2C5.8144 23.2 0.799988 18.1856 0.799988 12C0.799988 5.81441 5.8144 0.799999 12 0.799999C18.1856 0.799999 23.2 5.81441 23.2 12C23.2 18.1856 18.1856 23.2 12 23.2Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"></path>
                            </svg>
                            <span class="sidebar-link-text text-truncate" translate>my-profile</span>
                        </a>
                    </li>
                    <ng-template [ngxPermissionsOnly]="['Help Desk Plus','Security Administrator']">
                        <li class="sidebar-item nav-item">
                            <a id="users-tab" class="sidebar-link nav-link" [ngClass]="{ 'active' : search }"
                                routerLink="search" data-toggle="tab" data-target="#users" role="tab"
                                aria-controls="users" aria-selected="false" title="Users"
                                (click)="loadTitle('search-users') ; this.loadSVG('search')">
                                <svg class="sidebar-link-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M19.2 23.2H23.2V20.8C23.2 18.5909 21.4091 16.8 19.2 16.8M13.6 5.59687C13.6 8.24634 11.4512 10.3937 8.79999 10.3937C6.14879 10.3937 3.99999 8.24634 3.99999 5.59687C3.99999 2.9474 6.14879 0.799999 8.79999 0.799999C11.4512 0.799999 13.6 2.9474 13.6 5.59687ZM16.8 23.1854L0.799988 23.1854C0.799988 22.0493 0.799988 20.9685 0.799988 19.9898C0.799988 17.3388 2.94902 15.1906 5.59999 15.1906L12 15.1906C14.651 15.1906 16.8 17.3388 16.8 19.9898C16.8 20.9685 16.8 22.0493 16.8 23.1854ZM18.4 13.6C16.6327 13.6 15.2 12.1673 15.2 10.4C15.2 8.63269 16.6327 7.2 18.4 7.2C20.1673 7.2 21.6 8.63269 21.6 10.4C21.6 12.1673 20.1673 13.6 18.4 13.6Z"
                                        stroke="currentColor" stroke-width="1.5"></path>
                                </svg>
                                <span class="sidebar-link-text text-truncate" translate>search-users</span>
                            </a>
                        </li>
                    </ng-template>
                    <li class="sidebar-item nav-item">
                        <a id="link-tab" class="sidebar-link nav-link" [ngClass]="{ 'active' : accountLinking }"
                            routerLink="linkedAccounts" data-toggle="tab" data-target="#linked" role="tab"
                            aria-controls="linked" aria-selected="false" title="linked account"
                            (click)="loadTitle('linked-account') ; this.loadSVG('linkedAccounts')">
                            <svg class="sidebar-link-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path id="vector"
                                    d="M16.8326 13.6077L21.6648 8.77696C22.088 8.35403 22.4237 7.85192 22.6528 7.29929C22.8819 6.74665 22.9999 6.15432 23 5.55611C23.0001 4.9579 22.8823 4.36552 22.6535 3.81281C22.4246 3.26009 22.089 2.75786 21.6659 2.33479C21.2429 1.91171 20.7406 1.57608 20.1878 1.34706C19.635 1.11804 19.0425 1.00011 18.4441 1C17.8457 0.999894 17.2532 1.11762 16.7003 1.34644C16.1474 1.57527 15.645 1.91072 15.2218 2.33365L10.3896 7.1667M7.16596 10.3873L2.336 15.2181C1.481 16.0722 1.00043 17.2309 1 18.4392C0.999573 19.6476 1.47933 20.8066 2.33372 21.6614C3.18811 22.5161 4.34716 22.9965 5.55588 22.997C6.7646 22.9974 7.92399 22.5178 8.77899 21.6637L13.6112 16.8374M16.0285 7.97291L7.97252 16.0242"
                                    stroke="#3F4D62" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            
                            <span class="sidebar-link-text text-truncate" translate>Linked accounts</span>
                            <span class="badge badge-primary badge-pill font-weight-normal" translate>New</span>
                            <span class="linked-account-badge-mobile" *ngIf="!sidebarActive">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <rect width="12" height="12" rx="6" fill="#0066FF"/>
                                </svg>
                            </span>
                        </a>
                    </li>
                    <ng-template [ngxPermissionsOnly]="['Security Administrator']">
                        <li class="sidebar-item nav-item mt-3">
                            <a class="sidebar-link sidebar-link-primary nav-link" href="{{adminUri}}" target="_blank"
                                title="Admin UI">
                                <svg class="sidebar-link-icon sidebar-link-icon-primary" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.51039 0.800003L9.37279 1.49873L8.8464 4.05547C8.0048 4.37686 7.24159 4.8326 6.55199 5.37305L3.97919 4.5128L3.288 4.29854L2.9296 4.91573L1.15839 7.87696L0.799988 8.49424L1.32479 8.95313L3.3152 10.6464C3.2432 11.0893 3.1504 11.5274 3.1504 11.9927C3.1504 12.458 3.2432 12.8962 3.3152 13.3391L1.32479 15.0323L0.799988 15.4912L1.15839 16.1084L2.9296 19.0697L3.288 19.6885L3.97919 19.4727L6.55199 18.6124C7.24159 19.1529 8.0048 19.6085 8.8464 19.9299L9.37279 22.4866L9.51039 23.1855L14.488 23.1855L14.6272 22.4866L15.152 19.9299C15.9936 19.6085 16.7568 19.1529 17.4464 18.6124L20.0192 19.4727L20.7104 19.6885L21.0704 19.0697L22.84 16.1084L23.2 15.4912L22.6736 15.0323L20.6832 13.3391C20.7568 12.8962 20.848 12.458 20.848 11.9927C20.848 11.5274 20.7568 11.0893 20.6832 10.6464L22.6736 8.95313L23.2 8.49424L22.84 7.87696L21.0704 4.91573L20.7104 4.29854L20.0192 4.5128L17.4464 5.37305C16.7568 4.8326 15.9936 4.37686 15.152 4.05547L14.6272 1.49873L14.488 0.800003L9.51039 0.800003Z"
                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                        stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15.1994 11.9927C15.1994 13.7579 13.7658 15.1906 11.9994 15.1906C10.233 15.1906 8.7994 13.7579 8.7994 11.9927C8.7994 10.2274 10.233 8.79483 11.9994 8.79483C13.7658 8.79483 15.1994 10.2274 15.1994 11.9927Z"
                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                                        stroke-linejoin="round"></path>
                                </svg>
                                <span class="sidebar-link-text text-truncate" translate>IAM Admin</span>
                                <svg class="sidebar-link-text sidebar-link-icon sidebar-link-icon-primary" width="16"
                                    height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        d="M14.4 1.60003L14.4 6.49988M14.4 1.60003L9.50049 1.60003M14.4 1.60003L7.66318 8.33687"
                                        stroke="currentColor" stroke-miterlimit="16" stroke-linecap="square"></path>
                                    <path
                                        d="M12.379 9.78034L12.379 14.3999L1.60008 14.3999L1.60008 3.62094L6.21963 3.62094"
                                        stroke="currentColor" stroke-miterlimit="16" stroke-linecap="square"></path>
                                </svg>
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
        <div class="col col-lg-9">
            <div class="sidebar-content tab-content pl-1 pl-md-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../service/appconfig.service';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
  // templateUrl: '../../'+environment.path+'landing-page/landing-page.component.html',
  // styleUrls: ['../../'+environment.path+'landing-page/landing-page.component.css']
  
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router,
    private appConfigService: AppConfigService,
    private sharedService: SharedService) {
      this.router.events.subscribe((val: any)=>{
      this.loadUrl();
      });

     }

  myApplications: boolean = false;
  search: boolean = false;
  manageProfile: boolean = false;
  accountLinking:boolean = false;
  title: string = '';
  envProperties: any;
  adminUri: string = '';
  sidebarActive = false;
  changeIcon :boolean =true;

  ngOnInit(): void {
    this.loadUrl();
    this.envProperties = this.appConfigService.envProperties;
    this.adminUri = this.envProperties.adminSpaUri;
    this.sharedService.getProfilePageSubscription().subscribe(value => {
      this.loadUrl();
    })
  }

  toggleIcon(){
    this.changeIcon = !this.changeIcon;
  }

  loadUrl() {
    var url = this.router.url;
    if (url.includes('myApplications')) {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = false;
      this.accountLinking = false;
      this.title = 'my-applications';
    }
    if (url.includes('search')) {
      this.search = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.accountLinking = false;
      this.title = 'search-users';
    }
    if (url.includes('linkedAccounts')) {
      this.accountLinking = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.search = false;
      this.title = 'Linked Account';
    }
    if (url.includes('manageProfile')) {
      this.manageProfile = true;
      this.myApplications = false;
      this.search = false;
      this.accountLinking = false;
      this.title = 'my-profile';
    }
    if (url == '/') {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = true;
      this.loadSVG('myApplications');
      this.router.navigate(['/myApplications']);
      this.title = 'my-applications';
    }
  }


  loadSVG(myApplications: string) {
    // var url= this.router.url;
    if (myApplications === 'myApplications') {
      this.myApplications = true;
      this.search = false;
      this.manageProfile = false;
      this.accountLinking = false;
    }
    if (myApplications === 'search') {
      this.search = true;
      this.myApplications = false;
      this.manageProfile = false;
      this.accountLinking = false;
    }
    if (myApplications === 'manageProfile') {
      this.manageProfile = true;
      this.myApplications = false;
      this.search = false;
      this.accountLinking = false;
    }
    if (myApplications === 'accountLinking') {
      this.accountLinking = true;
      this.manageProfile = false;
      this.myApplications = false;
      this.search = false;
    }
  }

  loadTitle(title: string) {
    this.title = String(title);
  }

  toggleSidebar(){
    this.sidebarActive = !this.sidebarActive;
  }

}
